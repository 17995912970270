import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {


    // Inicializamos con valores predeterminados, pero estos pueden cambiar.
    private pageSource = new BehaviorSubject<number>(1);
    private searchSource = new BehaviorSubject<string>('');
    private selectedCategoryId = new BehaviorSubject<number>(1);
    private selectdYear = new BehaviorSubject<number>(0);
    private selectedStartDate = new BehaviorSubject<string>('');
    private selectedEndDate = new BehaviorSubject<string>('');
    // Observable para que otros componentes puedan suscribirse
    page$ = this.pageSource.asObservable();
    search$ = this.searchSource.asObservable();
    categoryId$ = this.selectedCategoryId.asObservable();
    year$ = this.selectdYear.asObservable();
    startDate$ = this.selectedStartDate.asObservable();
    endDate$ = this.selectedEndDate.asObservable();

    // Métodos para actualizar los valores
    updatePage(page: number): void {
      console.log("Pagina en searchService: " + page)
      this.pageSource.next(page);
    }

    updateSearch(search: string): void {
      console.log("Search en searchService: " + search)
      this.searchSource.next(search);
    }

    updateCategoryId(categoryId: number):void {
      console.log("CategoryId en searchService: " + categoryId)
      this.selectedCategoryId.next(categoryId);

    }

    updateYear(selectedYear: number | null) {
      this.selectdYear.next(selectedYear ?? 0)
    }

    updateEndDate(endDate: string) {
      this.selectedEndDate.next(endDate);
    }

    updateStartDate(startDate: string) {
      this.selectedStartDate.next(startDate);
    }

}
